import React, { useState, useRef } from "react";
import Axios from "axios";

import { RestaurantAddProfile } from "./RestaurantAddProfile";
import { RestaurantAddLocation } from "./RestaurantAddLocation";
import { RestaurantAddOpenHour } from "./RestaurantAddOpenHour";
import { RestaurantAddAdmin } from "./RestaurantAddAdmin";

import { api, header_token } from "../../config";
import swal from "sweetalert";
import { RestaurantHead } from "./RestaurantHead";

export const RestaurantAdd = () => {
  const [data, setdata] = useState({
    data: {
      area_id: "",
      categories_id: [""],
      credit: 0,
      email: "",
      full_name: "",
      is_closed: false,
      location: {
        address: "",
        district_id: "",
        latitude: 0,
        longitude: 0,
        province_id: "",
        sub_district_id: "",
      },
      name_en: "",
      name_th: "",
      open_day: {
        friday: {
          end_time: "",
          is_open: true,
          start_time: "",
        },
        monday: {
          end_time: "",
          is_open: true,
          start_time: "",
        },
        saturday: {
          end_time: "",
          is_open: true,
          start_time: "",
        },
        sunday: {
          end_time: "",
          is_open: true,
          start_time: "",
        },
        thursday: {
          end_time: "",
          is_open: true,
          start_time: "",
        },
        tuesday: {
          end_time: "",
          is_open: true,
          start_time: "",
        },
        wednesday: {
          end_time: "",
          is_open: true,
          start_time: "",
        },
      },
      personal_number: "",
      phone_numbers: "",
      picture: "",
      price: "",
      provider: {
        password: "",
        provider_id: "",
        provider_name: "password",
        provider_token: "",
        username: "",
      },
      register_date: "",
      status: 0,
      thumbnail: "",
      type: { value: "normal", label: "ยอดขาย GP" },
      // begin_type: "",
      // end_type: "",
      url: "",
    },
  });

  const profileRef = useRef(null);
  const locationRef = useRef(null);
  const openRef = useRef(null);
  const adminRef = useRef(null);

  const profileComponentRef = useRef(null);
  const locationComponentRef = useRef(null);
  const openComponentRef = useRef(null);
  const adminComponentRef = useRef(null);

  const callbackProfile = async (receive_data) => {
    let tmp = { ...data };
    // console.log("receive_data", receive_data);

    tmp.data.name_th = receive_data.name_th.trim();
    tmp.data.picture = receive_data.image;
    tmp.data.thumbnail = receive_data.thumbnail;
    tmp.data.phone_numbers = receive_data.phone_numbers.trim();
    tmp.data.full_name = receive_data.contact_name.trim();
    tmp.data.personal_number = receive_data.personal_number.trim();
    tmp.data.email = receive_data.email.trim();
    tmp.data.location.address = receive_data.address.trim();
    tmp.data.location.sub_district_id = receive_data.sub_district_id;
    tmp.data.location.district_id = receive_data.district_id;
    tmp.data.location.province_id = receive_data.province_id;
    tmp.data.area_id = receive_data.area_id;
    tmp.data.categories_id = receive_data.categories_id;
    tmp.data.register_date = receive_data.register_date;
    tmp.data.type = receive_data.type;
    // tmp.data.begin_type = receive_data.begin_type;
    // tmp.data.end_type = receive_data.end_type;

    tmp.data.credit = receive_data.credit;
    tmp.data.status = receive_data.status;

    // console.log("tmp", tmp);

    setdata({ ...tmp });

    // console.log("profile", data);
  };

  const callbackLocation = (receive_data) => {
    let tmp = { ...data };
    tmp.data.location.latitude = receive_data.lat;
    tmp.data.location.longitude = receive_data.lng;

    setdata(tmp);

    // console.log("location", data);
  };

  const callbackOpenHour = (receive_data) => {
    let tmp = { ...data };
    tmp.data.open_day = receive_data;

    setdata(tmp);

    // console.log("open", data);
  };

  const callbackAdmin = async (receive_data) => {
    let tmp = { ...data };
    //  tmp.data.email = receive_data.email;
    tmp.data.provider.username = receive_data.email.trim();
    tmp.data.provider.password = receive_data.password;
    tmp.data.provider.provider_name = "password";

    setdata(tmp);

    // console.log("admin", data);
  };

  const submit = async (send_data) => {
    // console.log("send_data", send_data);
    try {
      let res = await Axios.post(
        `${api}/branch/restaurants`,
        send_data.data,
        header_token
      );
      // console.log(res);
      if (res.status === 201) {
        // console.log("okkk");
        swal("สร้างร้านค้าเสร็จสิ้น !!", {
          icon: "success",
        });
        // history.push("/restaurant");
        window.location = "/restaurant";
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
      // console.log(;
    }
  };
  // const setopen_day = (receive) => {
  //   let tmp = data;
  //  tmp.data.open_day = receive;
  //   setdata(data);

  //   console.log(data);
  // };
  return (
    <div>
      <RestaurantHead />
      <div className="row">
        <div className="col-12 mb-4">
          <div className="card">
            <div className="card-body">
              <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="true"
                    ref={profileRef}
                    onClick={() => {
                      profileComponentRef.current.sendToParent();
                      locationComponentRef.current.sendToParent();
                      openComponentRef.current.sendToParent();
                      adminComponentRef.current.sendToParent();
                    }}
                  >
                    ข้อมูลร้านค้า
                  </a>
                </li>
                <li className="nav-item ">
                  <a
                    className="nav-link "
                    id="location-tab"
                    data-toggle="tab"
                    href="#location"
                    role="tab"
                    aria-controls="location"
                    aria-selected="false"
                    ref={locationRef}
                    onClick={() => {
                      profileComponentRef.current.sendToParent();
                      locationComponentRef.current.sendToParent();
                      openComponentRef.current.sendToParent();
                      adminComponentRef.current.sendToParent();
                    }}
                  >
                    Location สถานที่ตั้ง
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="openhouse-tab"
                    data-toggle="tab"
                    href="#openhouse"
                    role="tab"
                    aria-controls="openhouse"
                    aria-selected="false"
                    ref={openRef}
                    onClick={() => {
                      profileComponentRef.current.sendToParent();
                      locationComponentRef.current.sendToParent();
                      openComponentRef.current.sendToParent();
                      adminComponentRef.current.sendToParent();
                    }}
                  >
                    วันเวลาเปิด-ปิด
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="admin-tab"
                    data-toggle="tab"
                    href="#admin"
                    role="tab"
                    aria-controls="admin"
                    aria-selected="false"
                    ref={adminRef}
                    onClick={() => {
                      profileComponentRef.current.sendToParent();
                      locationComponentRef.current.sendToParent();
                      openComponentRef.current.sendToParent();
                      adminComponentRef.current.sendToParent();
                    }}
                  >
                    ตั้งค่า Admin
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane show active"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  {/* <a
                  href="#"
                  className="btn btn-primary  mr-2  mt-2 "
                  onClick={() => {
                    inputEl.current.click();
                  }}
                >
                  บันทึก
                </a> */}
                  <RestaurantAddProfile
                    ref={profileComponentRef}
                    nextRef={locationRef}
                    callbackProfile={callbackProfile}
                  />
                </div>
                <div
                  className="tab-pane  "
                  id="location"
                  role="tabpanel"
                  aria-labelledby="location-tab"
                >
                  <RestaurantAddLocation
                    ref={locationComponentRef}
                    nextRef={openRef}
                    callbackLocation={callbackLocation}
                  />
                </div>
                <div
                  className="tab-pane "
                  id="openhouse"
                  role="tabpanel"
                  aria-labelledby="openhouse-tab"
                >
                  <RestaurantAddOpenHour
                    ref={openComponentRef}
                    nextRef={adminRef}
                    callbackOpenHour={callbackOpenHour}
                  />
                </div>
                <div
                  className="tab-pane "
                  id="admin"
                  role="tabpanel"
                  aria-labelledby="admin-tab"
                >
                  <RestaurantAddAdmin
                    ref={adminComponentRef}
                    callbackAdmin={callbackAdmin}
                    submit={submit}
                    data={data}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
