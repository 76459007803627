import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";

import Axios from "axios";
import { api, img_url, header_token } from "../../config";
import swal from "sweetalert";
import moment from "moment";
import { RiderHead } from "./RiderHead";
import { NotiContext } from "../../store/keep";

const action_option = [
  { value: "approve", label: "อนุมัติ" },
  { value: "cancel", label: "ไม่อนุมัติ" },
];

export const RiderApprove = () => {
  const checkAllRef = useRef(null);
  const { noti } = useContext(NotiContext);

  const [loading, setloading] = useState(false);

  const [data, setdata] = useState([]);

  const [img, setimg] = useState("");
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit] = useState(10);
  const [total_count, settotal_count] = useState(0);

  const [filter_name, setfilter_name] = useState("");
  const [filter_status] = useState({
    value: "",
    label: "เลือก..",
  });
  const [filter_area] = useState({
    value: "",
    label: "ทั้งหมด",
  });
  const [filter_team] = useState({
    value: "",
    label: "ทั้งหมด",
  });
  const [filter_type, setfilter_type] = useState({
    value: "",
    label: "ทั้งหมด",
  });

  const [action, setaction] = useState({ value: "", label: "เลือก.." });

  const [option_employee_type, setoption_employee_type] = useState([]);

  const getOptionEmployee = async () => {
    try {
      let { data } = await Axios.get(`${api}/employee-type`);
      // console.log(data);
      let tmp = [];
      tmp.push({ value: "", label: "ทั้งหมด" });

      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_employee_type(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    setloading(true);
    try {
      let send_data = {
        // area_id: filter_area.value,
        employee_type_id: filter_type.value,
        full_name: filter_name,
        status:
          filter_status.value === "" ? [3] : [parseInt(filter_status.value)],
        team_id: filter_team.value,
        role: "rider",
      };

      let { data } = await Axios.post(
        `${api}/branch/user/filter?page=${current_page}&limit=${limit}`,
        send_data,
        header_token
      );
      // console.log("data", data);

      let cal_page_count = Math.ceil(data.meta.total_count / limit);
      setpage_count(cal_page_count);
      settotal_count(data.meta.total_count);

      if (data.users) {
        setdata(data.users);
      } else {
        setdata([]);
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const approveRider = async (rider_id, status) => {
    try {
      let send_data = {
        status: status,
        users_id: rider_id,
      };
      let res = await Axios.put(
        `${api}/branch/user/status`,
        send_data,
        header_token
      );

      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
      return false;
    }
  };

  useEffect(() => {
    getData();
    getOptionEmployee();
  }, []);

  useEffect(() => {
    setcurrent_page(1);
    getData();
  }, [filter_area, filter_team, filter_name, filter_type, filter_status]);

  useEffect(() => {
    getData();
  }, [current_page]);

  useEffect(() => {
    if (noti.getdata === "rider") {
      getData();
    }
  }, [noti]);

  return (
    <div>
      <RiderHead />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h4 className="mb-4 mt-2">ทั้งหมด {total_count} รายการ</h4>
                </div>

                <div>
                  <div className="form-row align-items-center mb-2 mr-2 ">
                    <div className=" mr-2" style={{ width: 200 }}>
                      <span className="tx-13 text-muted">ค้นหา</span>
                      <input
                        type="text"
                        className="form-control- global_filter"
                        id="global_filter"
                        placeholder="ชื่อ"
                        value={filter_name}
                        onChange={(e) => {
                          setfilter_name(e.target.value);
                        }}
                      />
                    </div>

                    <div className="ml-2 mr-3">
                      <div className="row">
                        <div style={{ width: 120 }}>
                          <span className="tx-13 text-muted">ประเภท</span>
                          <Select
                            value={filter_type}
                            onChange={(e) => {
                              setfilter_type(e);
                            }}
                            options={option_employee_type}
                            placeholder={"ประเภท.."}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col ml-1">
                      <div className="row">
                        <div style={{ width: 120 }}>
                          <span className="tx-13 text-muted">ดำเนินการ</span>
                          <Select
                            value={action}
                            onChange={(e) => {
                              setaction(e);
                            }}
                            options={action_option}
                            placeholder={"ดำเนินการ.."}
                          />
                        </div>
                      </div>
                    </div>

                    <div style={{ width: 130 }}>
                      <button
                        className="btn btn-primary ml-3"
                        style={{ marginTop: 20 }}
                        onClick={async () => {
                          let count = data.filter((e) => e.checked === true);
                          let txt =
                            action.value === "approve"
                              ? "อนุมัติ"
                              : "ไม่อนุมัติ";
                          if (action.value && count.length > 0) {
                            swal({
                              title: `ต้องการ${txt}ที่เลือกหรือไม่ ?`,
                              confirmButtonText: `${txt}`,
                              cancelButtonText: "ยกเลิก",
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then(async (willApprove) => {
                              if (willApprove) {
                                let sending = [];
                                data.forEach((value) => {
                                  if (value.checked === true) {
                                    sending.push(value.id);
                                  }
                                });

                                let s;
                                if (action.value === "approve") {
                                  s = 1;
                                } else if (action.value === "cancel") {
                                  s = 2;
                                }

                                let is_success = await approveRider(sending, s);

                                if (is_success) {
                                  getData();
                                  swal("เสร็จสิ้น !!", {
                                    icon: "success",
                                  });
                                } else {
                                  swal("เกิดข้อผิดพลาด !!", {
                                    icon: "error",
                                  });
                                }
                              }
                            });
                          } else {
                            alert("เลือกตัวดำเนินการ");
                          }
                        }}
                      >
                        ดำเนินการ
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <LoadingMask loading={loading} text={"loading..."}>
                <div style={{ minHeight: 500 }}>
                  <div className="table-container">
                    <table id="dataTable" className="table">
                      <thead>
                        <tr>
                          <th style={{ minWidth: 30 }}>
                            <input
                              type="checkbox"
                              className="selectAll "
                              name="selectAll"
                              defaultChecked={false}
                              ref={checkAllRef}
                              onClick={(e) => {
                                // console.log(checkAllRef.current.checked);
                                let tmp = [...data];
                                let _tmp = [];
                                tmp.forEach((value, idx) => {
                                  value.checked = checkAllRef.current.checked;
                                  _tmp.push(value);
                                });
                                setdata(_tmp);
                                // console.log(data);
                              }}
                            />
                          </th>
                          <th style={{ minWidth: 50 }}>รูป</th>
                          <th style={{ minWidth: 50 }}>รหัส</th>
                          <th style={{ minWidth: 250 }}>ชื่อ-นามสกุล</th>
                          <th style={{ minWidth: 150 }}>เลขที่บัตรประชาชน</th>
                          <th style={{ minWidth: 150 }}>วัน/เดือน/ปีเกิด</th>
                          <th style={{ minWidth: 300 }}>วุฒิการศึกษา</th>
                          <th style={{ minWidth: 300 }}>ที่อยู่</th>
                          <th style={{ minWidth: 120 }}>ตำบล/แขวง</th>
                          <th style={{ minWidth: 120 }}>อำเภอ/เขต</th>
                          <th style={{ minWidth: 120 }}>จังหวัด</th>
                          <th style={{ minWidth: 100 }}>รหัสไปรษณีย์</th>
                          <th style={{ minWidth: 200 }}>พื้นที่</th>
                          <th style={{ minWidth: 200 }}>ทีมงาน</th>
                          <th style={{ minWidth: 150 }}>เบอร์โทร</th>
                          <th style={{ minWidth: 100 }}>ID LIne</th>
                          <th style={{ minWidth: 100 }}>อีเมล์</th>
                          <th style={{ minWidth: 220 }}>เครดิต</th>
                          {/* <th style={{ minWidth: 100 }}>แต้ม</th> */}
                          <th style={{ minWidth: 150 }}>ประเภท</th>
                          <th style={{ minWidth: 120 }}>วันที่</th>
                          <th style={{ minWidth: 120 }}>พาหนะ</th>
                          <th style={{ minWidth: 100 }}>ทะเบียน</th>
                          <th style={{ minWidth: 100 }}>รุ่น</th>
                          <th style={{ minWidth: 100 }}>สี</th>
                          <th style={{ minWidth: 100 }}>ใบขับขี่</th>
                          <th style={{ minWidth: 150 }}>บัตรประชาชน</th>
                          <th style={{ minWidth: 80 }}>สถานะ</th>
                          <th style={{ minWidth: 80 }}>อนุมัติ</th>
                          <th style={{ minWidth: 120 }}>ไม่อนุมัติ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((value, index) => {
                          return (
                            <tr
                              key={index}
                              style={{
                                backgroundColor: value.checked ? "#e8eaed" : "",
                              }}
                            >
                              <td style={{ minWidth: 30 }}>
                                <input
                                  type="checkbox"
                                  checked={value.checked || ""}
                                  onChange={(e) => {
                                    let tmp = [...data];
                                    data.forEach((vl, idx) => {
                                      if (vl.id === value.id) {
                                        tmp[idx].checked = !tmp[idx].checked;
                                        setdata(tmp);
                                      }
                                    });
                                  }}
                                />
                              </td>
                              <td>
                                <div
                                  className="thumbnail cursor-img"
                                  data-toggle="modal"
                                  data-target=".show-img"
                                  onClick={() => {
                                    setimg(
                                      value.picture === ""
                                        ? "/images/no-img.jpg"
                                        : `${img_url}${value.picture}`
                                    );
                                  }}
                                >
                                  <img
                                    alt=""
                                    className="lazy img-responsive"
                                    src={
                                      value.picture === ""
                                        ? "/images/no-img.jpg"
                                        : `${img_url}${value.picture}`
                                    }
                                  />
                                </div>
                              </td>
                              <td>{value.user_id}</td>
                              <td>
                                <Link to={`/rider/history/${value.id}`}>
                                  <span>{value.full_name}</span>
                                </Link>
                              </td>
                              <td>{value.identification_number}</td>
                              <td>
                                {" "}
                                {moment(value.birth_date).format("DD/MM/YYYY")}
                              </td>
                              <td>{value.degree.name_th}</td>
                              <td>{value.location.address}</td>
                              <td>{value.location.sub_district.name_th}</td>
                              <td>{value.location.district.name_th}</td>
                              <td>{value.location.province.name_th}</td>
                              <td>{value.location.sub_district.zip_code}</td>
                              <td>
                                {value.area
                                  ? value.area.map((val, inx) => {
                                      return (
                                        <span key={inx}>{val.name_th}, </span>
                                      );
                                    })
                                  : ""}
                              </td>
                              <td>{value.team.name_th}</td>
                              <td>{value.phone_number}</td>
                              <td>{value.line_id}</td>
                              <td>{value.email}</td>
                              <td className="text-success">
                                <div>{value.credit}</div>
                              </td>
                              {/* <td>{value.point}</td> */}
                              <td>{value.employee_type.name_th}</td>
                              <td>
                                {moment(value.birth_date).format("DD/MM/YYYY")}
                              </td>
                              <td>{value.vehicle_type.name_th}</td>
                              <td>{value.driving_license}</td>
                              <td>{value.vehicle_make}</td>
                              <td>{value.vehicle_color}</td>
                              <td>
                                <div
                                  className="thumbnail cursor-img"
                                  data-toggle="modal"
                                  data-target=".show-img"
                                  onClick={() => {
                                    setimg(
                                      value.driving_picture === ""
                                        ? "/images/no-img.jpg"
                                        : `${img_url}${value.driving_picture}`
                                    );
                                  }}
                                >
                                  <img
                                    alt=""
                                    className="lazy img-responsive"
                                    src={
                                      value.driving_picture === ""
                                        ? "/images/no-img.jpg"
                                        : `${img_url}${value.driving_picture}`
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  className="thumbnail cursor-img"
                                  data-toggle="modal"
                                  data-target=".show-img"
                                  onClick={() => {
                                    setimg(
                                      value.identification_picture === ""
                                        ? "/images/no-img.jpg"
                                        : `${img_url}${value.identification_picture}`
                                    );
                                  }}
                                >
                                  <img
                                    alt=""
                                    className="lazy img-responsive"
                                    src={
                                      value.identification_picture === ""
                                        ? "/images/no-img.jpg"
                                        : `${img_url}${value.identification_picture}`
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                {(() => {
                                  switch (value.status) {
                                    case 0:
                                      return (
                                        <div className="badge badge-warning">
                                          ระงับ
                                        </div>
                                      );
                                    case 1:
                                      return (
                                        <div className="badge badge-success">
                                          ใช้งาน
                                        </div>
                                      );

                                    case 2:
                                      return (
                                        <div className="badge badge-danger">
                                          ไม่อนุมัติ
                                        </div>
                                      );
                                    case 3:
                                      return (
                                        <div className="badge badge-primary">
                                          รออนุมัติ
                                        </div>
                                      );

                                    default:
                                      break;
                                  }
                                })()}
                              </td>
                              <td>
                                <button
                                  className="btn btn-outline-primary btn-sm mb-1"
                                  onClick={() => {
                                    swal({
                                      title: `ต้องการอนุมัติหรือไม่ ?`,
                                      confirmButtonText: "อนุมัติ",
                                      cancelButtonText: "ยกเลิก",
                                      icon: "warning",
                                      buttons: true,
                                      dangerMode: true,
                                    }).then(async (willApprove) => {
                                      if (willApprove) {
                                        let is_success = await approveRider(
                                          [value.id],
                                          1
                                        );

                                        if (is_success) {
                                          getData();
                                          swal("เสร็จสิ้น !!", {
                                            icon: "success",
                                          });
                                        } else {
                                          swal("เกิดข้อผิดพลาด !!", {
                                            icon: "error",
                                          });
                                        }
                                      }
                                    });
                                  }}
                                >
                                  อนุมัติ
                                </button>
                              </td>
                              <td>
                                <button
                                  className="btn btn-outline-warning small-buttom btn-sm mb-1"
                                  onClick={() => {
                                    swal({
                                      title: `ต้องการไม่อนุมัติหรือไม่ ?`,
                                      confirmButtonText: "ไม่อนุมัติ",
                                      cancelButtonText: "ยกเลิก",
                                      icon: "warning",
                                      buttons: true,
                                      dangerMode: true,
                                    }).then(async (willApprove) => {
                                      if (willApprove) {
                                        let is_success = await approveRider(
                                          [value.id],
                                          2
                                        );

                                        if (is_success) {
                                          getData();
                                          swal("เสร็จสิ้น !!", {
                                            icon: "success",
                                          });
                                        } else {
                                          swal("เกิดข้อผิดพลาด !!", {
                                            icon: "error",
                                          });
                                        }
                                      }
                                    });
                                  }}
                                >
                                  ไม่อนุมัติ
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </LoadingMask>
              <div className="mt-2" style={{ float: "right" }}>
                <ReactPaginate
                  previousLabel={"← Previous"}
                  nextLabel={"Next →"}
                  breakLabel={<span className="gap">...</span>}
                  pageCount={page_count}
                  onPageChange={(e) => setcurrent_page(e.selected + 1)}
                  forcePage={current_page - 1}
                  containerClassName={"pagination"}
                  previousLinkClassName={"previous_page"}
                  nextLinkClassName={"next_page"}
                  disabledClassName={"disabled"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="showModdal" className="modal  show-img" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>รูปภาพ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <img
                alt=""
                src={img ? img : ""}
                style={{ maxWidth: 600, display: "block", margin: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
