import React, { useEffect, useState } from "react";
import { NavLink, useParams, Link } from "react-router-dom";
import { RiderDetailHead } from "./RiderDetailHead";
import { useForm } from "react-hook-form";

import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import Axios from "axios";
import swal from "sweetalert";
import moment from "moment";
import { api, restaurant_status_option, header_token } from "../../config";
export const RiderDetailHistory = () => {
  const { id } = useParams();
  const { register, handleSubmit, errors } = useForm();

  const [loading, setloading] = useState(false);
  const [data, setdata] = useState({
    location: { province: {}, sub_district: {}, district: {} },
    area: {},
    degree: {},
    team: {},
    vehicle_type: {},
  });

  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [confirm_password, setconfirm_password] = useState("");
  const [show, setshow] = useState(false);

  const getData = async () => {
    setloading(true);
    try {
      let { data } = await Axios.get(
        `${api}/branch/user/profile/${id}`,
        header_token
      );
      // console.log("getdata", data);
      setusername(data.username);
      setdata(data);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const onSubmit = async (data) => {
    // console.log(data);

    if (password === confirm_password) {
      try {
        let send_data = {
          password: password,
          provider_id: "",
          provider_name: "password",
          provider_token: "",
          username: username,
        };
        let res = await Axios.put(
          `${api}/branch/user/change-password/${id}`,
          send_data,
          header_token
        );

        // console.log(res);
        if (res.status === 201 || res.status === 200) {
          swal(`เปลี่ยนรหัสผ่านเสร็จสิ้น`, {
            icon: "success",
            button: false,
          });
        }
      } catch (error) {
        swal(`${error.response.data.message}`, {
          icon: "error",
          button: false,
        });
      }
    }
  };

  // console.log(errors);
  useEffect(() => {
    // console.log(id);
    getData();
  }, []);
  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap ">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">ประวัติพนักงาน</h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                พนักงานส่งทั้งหมด
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <RiderDetailHead />
              <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                <li className="nav-item">
                  <NavLink
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/rider/history/${id}`}
                  >
                    ประวัติพนักงาน
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/rider/service/${id}`}
                  >
                    ประวัติการบริการ
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/rider/credit/${id}`}
                  >
                    ประวัติการการเติมเครดิต
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/rider/withdraw/${id}`}
                  >
                    ประวัติการการถอนเงิน
                  </NavLink>
                </li>
              </ul>
              <LoadingMask loading={loading} text={"loading..."}>
                <div className="tab-content" id="myTabContent">
                  <div>
                    <table
                      style={{ width: "100%", tableLayout: "fixed" }}
                      border="0"
                    >
                      <tbody>
                        <tr>
                          <td width="20%">รหัสพนักงาน</td>
                          <td width="30%"> {data.user_id}</td>
                          <td width="20%">ประเภท</td>
                          <td width="30%">
                            {data.employee_type
                              ? data.employee_type.name_th
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td valign="top">ชื่อ-นามสกุล</td>
                          <td valign="top"> {data.full_name}</td>
                          <td valign="top">ทีมงาน</td>
                          <td valign="top">{data.team.name_th}</td>
                        </tr>
                        <tr>
                          <td valign="top">เลขที่บัตรประชาชน</td>
                          <td valign="top">{data.identification_number}</td>
                          <td valign="top">วันที่สมัคร</td>
                          <td valign="top">
                            {moment(data.register_date).format("DD/MM/YYYY")}
                          </td>
                        </tr>
                        <tr>
                          <td valign="top">เบอร์โทรศัพท์</td>
                          <td valign="top">{data.phone_number}</td>
                          <td valign="top">เครดิต</td>
                          <td valign="top">{data.credit}</td>
                        </tr>
                        <tr>
                          <td valign="top">ID Line</td>
                          <td valign="top">{data.line_id}</td>
                          {/* <td valign="top">แต้ม</td>
                          <td valign="top">{data.point}</td> */}
                          <td valign="top">ประเภทยานพาหนะ</td>
                          <td valign="top">{data.vehicle_type.name_th}</td>
                        </tr>
                        <tr>
                          <td valign="top">อีเมล</td>
                          <td valign="top">{data.email}</td>
                          <td valign="top">ทะเบียน</td>
                          <td valign="top">{data.license_plate}</td>
                        </tr>
                        <tr>
                          <td valign="top">วันเดือนปีเกิด</td>
                          <td valign="top">
                            {moment(data.birth_date).format("DD/MM/YYYY")}
                          </td>
                          <td valign="top">รุ่น</td>
                          <td valign="top">{data.vehicle_make}</td>
                        </tr>

                        <tr>
                          <td valign="top">ที่อยู่</td>
                          <td valign="top"> {data.location.address}</td>
                          <td valign="top">สี</td>
                          <td valign="top">{data.vehicle_color}</td>
                        </tr>

                        <tr>
                          <td valign="top">ตำบล</td>
                          <td valign="top">
                            {data.location.sub_district.name_th}
                          </td>
                          <td valign="top">สถานะ</td>
                          <td valign="top">
                            {(() => {
                              let _status = restaurant_status_option.filter(
                                (e) => e.value === data.status
                              );
                              // console.log(_status);
                              if (_status[0]) {
                                return `${_status[0].label}`;
                              }
                            })()}
                          </td>
                        </tr>
                        <tr>
                          <td valign="top">อำเภอ</td>
                          <td valign="top">{data.location.district.name_th}</td>
                          <td valign="top"></td>
                          <td valign="top"></td>
                        </tr>
                        <tr>
                          <td valign="top">จังหวัด</td>
                          <td valign="top">{data.location.province.name_th}</td>
                          <td valign="top"></td>
                          <td valign="top"></td>
                        </tr>

                        <tr>
                          <td valign="top">รหัสไปรษณีย์</td>
                          <td valign="top">
                            {data.location.sub_district.zip_code}
                          </td>
                          <td valign="top"></td>
                          <td valign="top"></td>
                        </tr>
                        <tr>
                          <td valign="top">พื้นที่</td>
                          <td valign="top">
                            {data
                              ? data.area
                                ? data.area.length > 0
                                  ? data.area.map((val, inx) => {
                                      return (
                                        <span key={inx}>{val.name_th}, </span>
                                      );
                                    })
                                  : ""
                                : ""
                              : ""}
                          </td>
                          <td valign="top"></td>
                          <td valign="top"></td>
                        </tr>

                        <tr>
                          <td valign="top">วุฒิการศึกษา</td>
                          <td
                            align="top"
                            style={{ paddingRight: 100, textAlign: "left" }}
                          >
                            {data.degree.name_th}
                          </td>
                          <td valign="top"></td>
                          <td valign="top"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="row">
                    <a
                      tabIndex="0"
                      data-toggle="modal"
                      data-target=".changePassword"
                      className="btn btn-primary mr-3  ml-auto"
                    >
                      เปลี่ยนรหัสผ่าน
                    </a>
                    <Link
                      to={`/rider/edit/${id}`}
                      className="btn btn-primary mr-2"
                    >
                      แก้ไข
                    </Link>
                  </div>
                </div>
              </LoadingMask>
            </div>
          </div>
        </div>
      </div>

      <div id="showModdal" className="modal  changePassword" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <span>รูปภาพ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="form-group row">
                  <div className="col-lg-1"></div>
                  <div className="col-lg-3">
                    <label htmlFor="username">Username</label>
                  </div>
                  <div className="col-lg-7">
                    <input
                      id="username"
                      className={`form-control ${
                        errors.username ? "error-input" : ""
                      }`}
                      name="username"
                      type="text"
                      ref={register({ required: true, min: 6 })}
                      value={username}
                      onChange={(e) => {
                        setusername(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-1"></div>
                </div>

                {password !== confirm_password ? (
                  <div className=" row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-5">
                      <label htmlFor="name" style={{ color: "red" }}>
                        รหัสผ่านไม่ตรงกัน
                      </label>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="form-group row">
                  <div className="col-lg-1"></div>

                  <div className="col-lg-3">
                    <label htmlFor="password">Password</label>
                  </div>
                  <div className="col-lg-7">
                    <input
                      id="password"
                      className={`form-control ${
                        errors.password ? "error-input" : ""
                      }`}
                      name="password"
                      type={show ? "text" : "password"}
                      ref={register({ required: true })}
                      value={password}
                      onChange={async (e) => {
                        await setpassword(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-1"></div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-1"></div>

                  <div className="col-lg-3">
                    <label htmlFor="confirm_password">Confirm password</label>
                  </div>
                  <div className="col-lg-7">
                    <input
                      id="confirm_password"
                      className={`form-control ${
                        errors.confirm_password ? "error-input" : ""
                      }`}
                      name="confirm_password"
                      type={show ? "text" : "password"}
                      // ref={register({ required: true })}
                      value={confirm_password}
                      onChange={async (e) => {
                        await setconfirm_password(e.target.value);
                      }}
                    />
                    <div className="form-check mt-4 ml-4">
                      <input
                        type="checkbox"
                        className="form-check-input "
                        checked={show}
                        onChange={() => setshow(!show)}
                      />
                      Show Password
                    </div>
                  </div>
                  <div className="col-lg-1"></div>
                </div>
              </div>

              <div className="row mr-2 mb-4">
                <div className="col-12">
                  <div className="row">
                    <a
                      tabIndex="0"
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-outline-primary mr-2 ml-auto "
                    >
                      ยกเลิก
                    </a>
                    <button
                      // data-dismiss="modal"
                      // aria-label="Close"
                      className="btn btn-primary  mr-2 "
                      type="submit"
                    >
                      เปลี่ยนรหัสผ่าน
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
