import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { NotiContext, RulesContext } from "../store/keep";

export const Sidebar = (props) => {
  const { noti } = useContext(NotiContext);
  const { rules } = useContext(RulesContext);

  return (
    <nav className="sidebar sidebar-offcanvas " id="sidebar">
      <ul className="nav ">
        <NavLink
          className={"nav-item "}
          activeClassName="active"
          to={`/`}
          style={{ textDecoration: "none" }}
          exact
        >
          <span className="nav-link" to="/">
            <span className="menu_icon">
              <img alt="" src={"/images/icon/dashboard_line.svg"} />
            </span>
            <span className="menu-title">หน้าหลัก</span>
          </span>
        </NavLink>

        <NavLink
          className={"nav-item "}
          activeClassName="active"
          to={`/rider`}
          style={{ textDecoration: "none" }}
        >
          <span className="nav-link" to="/rider">
            <span className="menu_icon">
              <img alt="" src={"/images/icon/helmet.svg"} />
            </span>
            <span className="menu-title">
              พนักงานขับรถ
              <span className="badge badge-pill badge-danger ml-1">
                {noti.rider > 0 ? noti.rider : ""}
              </span>
            </span>
          </span>
        </NavLink>

        <NavLink
          className={"nav-item "}
          activeClassName="active"
          to={`/restaurant`}
          style={{ textDecoration: "none" }}
        >
          <span className="nav-link" to="/restaurant">
            <span className="menu_icon">
              <img alt="" src={"/images/icon/food-and-restaurant_line.svg"} />
            </span>
            <span className="menu-title">
              ร้านอาหาร
              <span className="badge badge-pill badge-danger ml-1">
                {noti.restaurant > 0 ? noti.restaurant : ""}
              </span>
            </span>
          </span>
        </NavLink>

        <NavLink
          className={"nav-item "}
          activeClassName="active"
          to={`/order`}
          style={{ textDecoration: "none" }}
        >
          <span className="nav-link" to="/order">
            <span className="menu_icon">
              <img alt="" src={"/images/icon/waiter.svg"} />
            </span>
            <span className="menu-title">
              คำสั่งซื้อ
              <span className="badge badge-pill badge-danger ml-1">
                {noti.order > 0 ? noti.order : ""}
              </span>
            </span>
          </span>
        </NavLink>

        {/* <NavLink
          className={"nav-item "}
          activeClassName="active"
          to={`/car_service_order`}
          style={{ textDecoration: "none" }}
        >
          <span className="nav-link" to="/car_service_order">
            <span className="menu_icon">
              <img alt="" src={"/images/icon/car.svg"} />
            </span>
            <span className="menu-title">
              เรียกรถ
              <span className="badge badge-pill badge-danger ml-1">
                {noti.car_service > 0 ? noti.car_service : ""}
              </span>
            </span>
          </span>
        </NavLink> */}
        {/* 
        <NavLink
          className={"nav-item "}
          activeClassName="active"
          to={`/sending`}
          style={{ textDecoration: "none" }}
        >
          <span className="nav-link" to="/sending">
            <span className="menu_icon">
              <img alt="" src={"/images/icon/delivery_line.svg"} />
            </span>
            <span className="menu-title">การจัดส่ง</span>
          </span>
        </NavLink> */}

        <NavLink
          className={"nav-item "}
          activeClassName="active"
          to={`/sale_bill`}
          style={{ textDecoration: "none" }}
        >
          <span className="nav-link" to="/sale_bill">
            <span className="menu_icon">
              <img alt="" src={"/images/icon/report_line.svg"} />
            </span>
            <span className="menu-title">ยอดขาย/ค่าบริการ</span>
          </span>
        </NavLink>

        <NavLink
          className={"nav-item "}
          activeClassName="active"
          to={`/restaurant_promotion`}
          style={{ textDecoration: "none" }}
        >
          <span className="nav-link" to="/restaurant_promotion">
            <span className="menu_icon">
              <img alt="" src={"/images/icon/discount.svg"} />
            </span>
            <span className="menu-title">โปรโมชั่นค่าส่ง</span>
          </span>
        </NavLink>

        {/* <NavLink
          className={"nav-item "}
          activeClassName="active"
          to={`/credit`}
          style={{ textDecoration: "none" }}
        >
          <span className="nav-link" to="/credit">
            <span className="menu_icon">
              <img alt="" src={"/images/icon/money.svg"} />
            </span>
            <span className="menu-title">
              เติมเครดิต
              <span className="badge badge-pill badge-danger ml-1">
                {noti.credit > 0 ? noti.credit : ""}
              </span>
            </span>
          </span>
        </NavLink>

        <NavLink
          className={"nav-item "}
          activeClassName="active"
          to={`/withdraw`}
          style={{ textDecoration: "none" }}
        >
          <span className="nav-link" to="/withdraw">
            <span className="menu_icon">
              <img alt="" src={"/images/icon/atm-machine.svg"} />
            </span>
            <span className="menu-title">
              ถอนเงิน
              <span className="badge badge-pill badge-danger ml-1">
                {noti.withdraw > 0 ? noti.withdraw : ""}
              </span>
            </span>
          </span>
        </NavLink> */}

        <NavLink
          className={"nav-item "}
          activeClassName="active"
          to={`/promotion_banner`}
          style={{ textDecoration: "none" }}
        >
          <span className="nav-link" to="/promotion_banner">
            <span className="menu_icon">
              <img alt="" src={"/images/icon/sale.svg"} />
            </span>
            <span className="menu-title">โปรโมชั่นแบนเนอร์</span>
          </span>
        </NavLink>

        <NavLink
          className={"nav-item sidebar-hover"}
          activeClassName="active"
          to={`/recommened`}
          style={{ textDecoration: "none" }}
        >
          <span className="nav-link" to="/recommened">
            <span className="menu_icon" style={{ width: 35 }}>
              <img alt="" src={"/images/icon/like_line.svg"} />
            </span>
            <span className="menu-title">ร้านแนะนำ</span>
          </span>
        </NavLink>

        {/* <NavLink
          className={"nav-item "}
          activeClassName="active"
          to={`/setting`}
          style={{ textDecoration: "none" }}
        >
          <span className="nav-link" to="/setting">
            <span className="menu_icon">
              <img alt="" src={"/images/icon/settings_line.svg"} />
            </span>
            <span className="menu-title">ตั้งค่า</span>
          </span>
        </NavLink> */}
      </ul>
    </nav>
  );
};
